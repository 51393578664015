import dynamic from 'next/dynamic'
import React from 'react'
import { InstallButton } from './InstallButton'

const InstallApp = dynamic(() => import('./InstallApp'), {
  ssr: false,
  loading: () => <InstallButton isDisabled />
})

export const InstallAppNoSsr = () => {
  return <InstallApp />
}
