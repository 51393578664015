'use client'

import { LandingSection } from '#/src/app/(landing)/_components/LandingSection'
import { Button, Grid, GridItem, Heading, Text } from '@chakra-ui/react'
import { AppLink } from 'ui'

const dictTexts = {
  sv: {
    title: 'Vill du sälja din hemlagade mat?',
    desc: 'Klicka och läs mer om fördelarna med att laga och sälja din mat hos oss.',
    button: 'Läs mer'
  },
  en: {
    title: 'Do you want to sell your homemade food?',
    desc: 'Click and read more about the advantages of cooking and selling your food with us.',
    button: 'Read more'
  }
}

export const LandingSectionMoreInfo = () => {
  const texts = dictTexts.en

  return (
    <LandingSection bg="primary.900">
      <Grid
        alignItems="center"
        color="text.invert"
        gap={{
          base: 6,
          sm: 16
        }}
        gridTemplateAreas={{
          base: `
            "title"
            "text"
            "button"
          `,
          sm: `
            "title title"
            "text button"
          `,
          md: `
            "title text button"
          `
        }}>
        <GridItem gridArea="title">
          <Heading as="h2" size="lg">
            {texts.title}
          </Heading>
        </GridItem>

        <GridItem gridArea="text">
          <Text as="p" size="md">
            {texts.desc}
          </Text>
        </GridItem>

        <GridItem gridArea="button">
          <Button
            color="text.default"
            colorScheme="info"
            minW="160px"
            rounded="3xl"
            href="/faqs"
            as={AppLink}
            _hover={{
              textDecoration: 'none'
            }}>
            {texts.button}
          </Button>
        </GridItem>
      </Grid>
    </LandingSection>
  )
}
