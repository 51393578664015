'use client'

import { LandingSection } from '#/src/app/(landing)/_components/LandingSection'
import { VStack } from '@chakra-ui/react'
import logoImg from '#/src/images/gourmate-logo-sharp.svg'
import { ImageBox } from 'ui'
import { InstallAppNoSsr } from '#/src/components/Install/InstallAppNoSsr'

export const LandingSectionDownload = () => {
  return (
    <LandingSection bg="background.surface">
      <VStack gap="6">
        <ImageBox
          h="125px"
          imageProps={{
            src: logoImg
          }}
          mb="6vh"
          w="125px"
        />

        <InstallAppNoSsr />
      </VStack>
    </LandingSection>
  )
}
