'use client'

import { LandingSection } from '#/src/app/(landing)/_components/LandingSection'
import {
  AspectRatio,
  Center,
  GridItem,
  Heading,
  HStack,
  List,
  ListItem,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import screenshotImg from '#/src/app/(landing)/images/how-screenshot.png'
import { ImageBox } from 'ui'

const dictTexts = {
  sv: {
    title: 'Så funkar det',
    items: [
      {
        title: 'Hitta hemlagad mat i ditt område',
        text: 'Testa något nytt - eller sök efter den mat du är sugen pa just nu.'
      },
      {
        title: 'Hämta nu - eller senare',
        text: 'Välj om du vill hämta maten med en gång eller en senare tid som passar dig.'
      },
      {
        title: 'Njut av vällagad mat!',
        text:
          'De som säljer mat i GourMate, gör det för att de vill dela med sig av matglädje och kunskap. ' +
          'Dessutom har de alla genomgott vår grundkurs.'
      },
      {
        title: 'Betygsätt din upplevelse',
        text: 'Det var gott, eller hur? Genom att tala om vad du tyckte, hjälper du andra att hitta god, hemlagad mat.'
      }
    ]
  },
  en: {
    title: "That's how it works",
    items: [
      {
        title: 'Find homemade food in your area',
        text: "Try something new - or search for the food you're craving right now."
      },
      {
        title: 'Download now - or later',
        text: 'Choose whether you want to pick up the food right away or at a later time that suits you.'
      },
      {
        title: 'Enjoy well-prepared food!',
        text: `
          Those who sell food in GourMate do so because they want to share food joy and knowledge. 
          In addition, they have all completed our basic course.
        `
      },
      {
        title: 'Rate your experience',
        text: 'That tasted good, right? By sharing what you thought, you help others find good, home-cooked food.'
      }
    ]
  }
}

export const LandingSectionHow = () => {
  const texts = dictTexts.en

  return (
    <LandingSection bg="#fff">
      <SimpleGrid
        alignItems="center"
        columns={{
          base: 1,
          md: 2
        }}
        gap={{
          base: 12,
          md: 16
        }}>
        <GridItem
          order={{
            base: 2,
            md: 1
          }}>
          <AspectRatio
            maxH={{
              base: '400px',
              md: 'none'
            }}
            ratio={930 / 644}>
            <ImageBox
              imageProps={{
                src: screenshotImg,
                alt: 'Gourmate offer example',
                style: {
                  objectFit: 'contain'
                }
              }}
              w="100%"
            />
          </AspectRatio>
        </GridItem>

        <GridItem
          order={{
            base: 1,
            md: 2
          }}>
          <VStack alignItems="flex-start" gap="10">
            <Heading as="h2" size="lg">
              {texts.title}
            </Heading>

            <List alignItems="flex-start" as={VStack} gap="8">
              {texts.items.map(({ title, text }, index) => (
                <ListItem key={index}>
                  <HStack alignItems="flex-start" gap={4}>
                    <Center
                      bg="primary.100"
                      fontSize="lg"
                      lineHeight={0}
                      minHeight="2em"
                      minWidth="2em"
                      rounded="50%">
                      {index + 1}
                    </Center>

                    <VStack alignItems="flex-start" gap={3}>
                      <Heading as="h3" size="md">
                        {title}
                      </Heading>

                      <Text lineHeight="double">{text}</Text>
                    </VStack>
                  </HStack>
                </ListItem>
              ))}
            </List>
          </VStack>
        </GridItem>
      </SimpleGrid>
    </LandingSection>
  )
}
