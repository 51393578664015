'use client'

import { LandingSection } from '#/src/app/(landing)/_components/LandingSection'
import { AspectRatio, Grid, GridItem, Heading, Text, VStack } from '@chakra-ui/react'
import logoImg from '#/src/images/gourmate-logo-sharp.svg'
import screenshotImg from '#/src/app/(landing)/images/intro-screenshot.png'
import { ImageBox } from 'ui'
import { InstallAppNoSsr } from '#/src/components/Install/InstallAppNoSsr'

const dictTexts = {
  sv: {
    subTitle: 'Prova något nytt',
    title: 'Hemlagat – direkt till dig',
    desc: `
      GourMate hjälper dig att hitta god mat lagad av personer i ditt område. En granne,
      vän eller kollega kanske lagar världens godaste rätt - och det kanske räcker till
      dig också! Nyfiken? Installera appen nedan och ta del av gemenskapen.
    `
  },
  en: {
    subTitle: 'Try something new',
    title: 'Homemade - straight to you',
    desc: `
      GourMate helps you find good food prepared by people in your area. 
      A neighbor, friend or colleague might cook the world's best dish - and that might be enough for you, too! 
      Curious? Install the app below and join the community.
    `
  }
}

export const LandingSectionIntro = () => {
  const texts = dictTexts.en

  return (
    <LandingSection bg="background.surface">
      <Grid
        alignItems="center"
        gap={10}
        templateColumns={{
          base: '1fr',
          sm: '5fr 4fr'
        }}>
        <GridItem
          alignItems={{
            base: 'center',
            sm: 'flex-start'
          }}
          as={VStack}>
          <ImageBox
            h="125px"
            imageProps={{
              src: logoImg
            }}
            mb="6vh"
            w="125px"
          />

          <VStack
            alignItems={{
              base: 'center',
              sm: 'flex-start'
            }}
            gap={8}>
            <VStack
              alignItems={{
                base: 'center',
                sm: 'flex-start'
              }}
              gap={4}
              textAlign={{
                base: 'center',
                sm: 'left'
              }}>
              <Text color="primary.700" fontSize="sm" textTransform="uppercase">
                {texts.subTitle}
              </Text>

              <Heading as="h1" size="xl">
                {texts.title}
              </Heading>

              <Text as="p" fontSize="lg">
                {texts.desc}
              </Text>
            </VStack>

            <InstallAppNoSsr />
          </VStack>
        </GridItem>

        <GridItem>
          <AspectRatio
            maxH={{
              base: '500px',
              sm: 'none'
            }}
            ratio={5 / 7}>
            <ImageBox
              h="100%"
              imageProps={{
                src: screenshotImg,
                alt: 'Gourmate example',
                style: {
                  objectFit: 'contain'
                }
              }}
              w="100%"
            />
          </AspectRatio>
        </GridItem>
      </Grid>
    </LandingSection>
  )
}
