import { Box, BoxProps } from '@chakra-ui/react'

type LandingSectionProps = BoxProps

export const LandingSection = ({ children, ...props }: LandingSectionProps) => {
  return (
    <Box py="4vh" w="100%" {...props}>
      <Box margin="auto" maxWidth="1100px" p={6} w="100%">
        {children}
      </Box>
    </Box>
  )
}
