'use client'

import { LandingSection } from '#/src/app/(landing)/_components/LandingSection'
import { Box, Icon, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { IoEarthOutline } from 'react-icons/io5'
import { LuSandwich } from 'react-icons/lu'
import { PiCoffee } from 'react-icons/pi'
import { FramerMotionBox } from 'ui'

const dictTexts = {
  sv: {
    items: [
      {
        title: 'Bra för planeten',
        content: (
          <>
            Med GourMate minskar vi matsvinnnet.
            <br />
            Mindre rester, mer matglädje.
          </>
        )
      },
      {
        title: 'Bra för variationen',
        content: 'Prova något nytt. Med Gourmate får du omväxling.'
      },
      {
        title: 'Bra för stressnivån',
        content: 'Låt någon annan laga maten, så är det inte din tid som försvinner.'
      }
    ]
  },
  en: {
    items: [
      {
        title: 'Good for the planet',
        content: (
          <>
            With GourMate we reduce food waste.
            <br />
            Less leftovers, more food enjoyment.
          </>
        )
      },
      {
        title: 'Good for variety',
        content: 'Try something new. With Gourmate you get variety.'
      },
      {
        title: 'Good for stress levels',
        content: "Let someone else do the cooking, so it's not your time wasted."
      }
    ]
  }
}

type ItemProps = {
  icon: ReactNode
  title: ReactNode
  text: ReactNode
}
const Item = ({ icon, title, text }: ItemProps) => {
  return (
    <VStack
      as={FramerMotionBox}
      gap={4}
      initial={{ opacity: 0, y: 50 }}
      textAlign="center"
      viewport={{ once: false }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}>
      <Box fontSize="5xl">{icon}</Box>

      <Text fontSize="lg">{title}</Text>

      <Text>{text}</Text>
    </VStack>
  )
}
export const LandingSectionAdvantages = () => {
  const texts = dictTexts.en
  const icons = [IoEarthOutline, LuSandwich, PiCoffee]
  const items = texts.items.map((item, index) => ({
    ...item,
    icon: icons[index]
  }))

  return (
    <LandingSection bg="primary.500" color="text.invert">
      <SimpleGrid
        columns={{
          base: 1,
          sm: 3
        }}
        gap={{
          base: 12,
          sm: 16
        }}>
        {items.map(({ icon, title, content }, index) => (
          <Item key={index} icon={<Icon as={icon} />} text={content} title={title} />
        ))}
      </SimpleGrid>
    </LandingSection>
  )
}
