import { Button, ButtonProps } from '@chakra-ui/react'

type InstallButtonProps = ButtonProps

export const InstallButton = (props: InstallButtonProps) => {
  return (
    <Button colorScheme="secondary" rounded="3xl" size="lg" {...props}>
      Install GourMate
    </Button>
  )
}
