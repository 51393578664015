import(/* webpackMode: "eager" */ "/vercel/path0/apps/web-gourmate/src/app/(landing)/_components/LandingSectionAdvantages.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web-gourmate/src/app/(landing)/_components/LandingSectionDownload.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web-gourmate/src/app/(landing)/_components/LandingSectionHow.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web-gourmate/src/app/(landing)/_components/LandingSectionIntro.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web-gourmate/src/app/(landing)/_components/LandingSectionMoreInfo.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+accordion@2.3.1_@chakra-ui+system@2.6.2_framer-motion@10.18.0_react@18.2.0/node_modules/@chakra-ui/accordion/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+alert@2.2.2_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/alert/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+avatar@2.3.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/avatar/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+breadcrumb@2.2.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/breadcrumb/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+button@2.1.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/button/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+card@2.2.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/card/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+checkbox@2.3.2_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/checkbox/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+close-button@2.1.1_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/close-button/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+color-mode@2.2.0_react@18.2.0/node_modules/@chakra-ui/color-mode/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+control-box@2.1.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/control-box/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+counter@2.1.0_react@18.2.0/node_modules/@chakra-ui/counter/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+css-reset@2.3.0_@emotion+react@11.11.3_react@18.2.0/node_modules/@chakra-ui/css-reset/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+editable@3.1.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/editable/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+focus-lock@2.1.0_@types+react@18.2.48_react@18.2.0/node_modules/@chakra-ui/focus-lock/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+form-control@2.2.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/form-control/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+hooks@2.2.1_react@18.2.0/node_modules/@chakra-ui/hooks/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+icon@3.2.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/icon/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+image@2.1.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/image/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+input@2.1.2_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/input/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+layout@2.3.1_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/layout/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+media-query@3.3.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/media-query/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+menu@2.2.1_@chakra-ui+system@2.6.2_framer-motion@10.18.0_react@18.2.0/node_modules/@chakra-ui/menu/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+modal@2.3.1_@chakra-ui+system@2.6.2_@types+react@18.2.48_framer-motion@10.18.0_react-dom@18.2.0_react@18.2.0/node_modules/@chakra-ui/modal/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+number-input@2.1.2_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/number-input/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+pin-input@2.1.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/pin-input/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+popover@2.2.1_@chakra-ui+system@2.6.2_framer-motion@10.18.0_react@18.2.0/node_modules/@chakra-ui/popover/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+popper@3.1.0_react@18.2.0/node_modules/@chakra-ui/popper/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+portal@2.1.0_react-dom@18.2.0_react@18.2.0/node_modules/@chakra-ui/portal/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+progress@2.2.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/progress/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+radio@2.1.2_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/radio/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+react-env@3.1.0_react@18.2.0/node_modules/@chakra-ui/react-env/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+react@2.8.2_@emotion+react@11.11.3_@emotion+styled@11.11.0_@types+react@18.2.48_fr_y73rnwezm3pxlghcinj7podcna/node_modules/@chakra-ui/react/dist/chunk-QAITB7GG.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+select@2.1.2_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/select/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+skeleton@2.1.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/skeleton/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+skip-nav@2.1.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/skip-nav/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+slider@2.1.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/slider/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+spinner@2.1.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/spinner/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+stat@2.1.1_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/stat/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+stepper@2.3.1_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/stepper/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+switch@2.1.2_@chakra-ui+system@2.6.2_framer-motion@10.18.0_react@18.2.0/node_modules/@chakra-ui/switch/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+system@2.6.2_@emotion+react@11.11.3_@emotion+styled@11.11.0_react@18.2.0/node_modules/@chakra-ui/system/dist/chunk-5PL47M24.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+system@2.6.2_@emotion+react@11.11.3_@emotion+styled@11.11.0_react@18.2.0/node_modules/@chakra-ui/system/dist/chunk-7FWEOSAE.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+system@2.6.2_@emotion+react@11.11.3_@emotion+styled@11.11.0_react@18.2.0/node_modules/@chakra-ui/system/dist/chunk-DMO4EI7P.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+system@2.6.2_@emotion+react@11.11.3_@emotion+styled@11.11.0_react@18.2.0/node_modules/@chakra-ui/system/dist/chunk-FDQH4LQI.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+system@2.6.2_@emotion+react@11.11.3_@emotion+styled@11.11.0_react@18.2.0/node_modules/@chakra-ui/system/dist/chunk-I77ZCDZJ.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+system@2.6.2_@emotion+react@11.11.3_@emotion+styled@11.11.0_react@18.2.0/node_modules/@chakra-ui/system/dist/chunk-MFVQSVQB.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+system@2.6.2_@emotion+react@11.11.3_@emotion+styled@11.11.0_react@18.2.0/node_modules/@chakra-ui/system/dist/chunk-UIGT7YZF.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+system@2.6.2_@emotion+react@11.11.3_@emotion+styled@11.11.0_react@18.2.0/node_modules/@chakra-ui/system/dist/chunk-ZHQNHOQS.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+system@2.6.2_@emotion+react@11.11.3_@emotion+styled@11.11.0_react@18.2.0/node_modules/@chakra-ui/system/dist/chunk-ZJJGQIVY.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+table@2.1.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/table/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+tabs@3.0.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/tabs/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+tag@3.1.1_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/tag/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+textarea@2.1.2_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/textarea/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+toast@7.0.2_@chakra-ui+system@2.6.2_framer-motion@10.18.0_react-dom@18.2.0_react@18.2.0/node_modules/@chakra-ui/toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+tooltip@2.3.1_@chakra-ui+system@2.6.2_framer-motion@10.18.0_react-dom@18.2.0_react@18.2.0/node_modules/@chakra-ui/tooltip/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+transition@2.1.0_framer-motion@10.18.0_react@18.2.0/node_modules/@chakra-ui/transition/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@chakra-ui+visually-hidden@2.2.0_@chakra-ui+system@2.6.2_react@18.2.0/node_modules/@chakra-ui/visually-hidden/dist/index.mjs")